// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-add-skill-index-tsx": () => import("./../../../src/pages/add-skill/index.tsx" /* webpackChunkName: "component---src-pages-add-skill-index-tsx" */),
  "component---src-pages-add-work-sample-index-tsx": () => import("./../../../src/pages/add-work-sample/index.tsx" /* webpackChunkName: "component---src-pages-add-work-sample-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-complete-index-tsx": () => import("./../../../src/pages/order-complete/index.tsx" /* webpackChunkName: "component---src-pages-order-complete-index-tsx" */),
  "component---src-pages-password-reset-index-tsx": () => import("./../../../src/pages/password-reset/index.tsx" /* webpackChunkName: "component---src-pages-password-reset-index-tsx" */),
  "component---src-pages-profile-details-index-tsx": () => import("./../../../src/pages/profile-details/index.tsx" /* webpackChunkName: "component---src-pages-profile-details-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-profile-settings-index-tsx": () => import("./../../../src/pages/profile-settings/index.tsx" /* webpackChunkName: "component---src-pages-profile-settings-index-tsx" */),
  "component---src-pages-puzzle-editor-index-tsx": () => import("./../../../src/pages/puzzle-editor/index.tsx" /* webpackChunkName: "component---src-pages-puzzle-editor-index-tsx" */),
  "component---src-pages-sign-in-index-tsx": () => import("./../../../src/pages/sign-in/index.tsx" /* webpackChunkName: "component---src-pages-sign-in-index-tsx" */),
  "component---src-pages-sign-up-index-tsx": () => import("./../../../src/pages/sign-up/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-index-tsx" */),
  "component---src-pages-sign-up-required-index-tsx": () => import("./../../../src/pages/sign-up-required/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-required-index-tsx" */),
  "component---src-templates-puzzle-front-page-index-tsx": () => import("./../../../src/templates/PuzzleFrontPage/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-front-page-index-tsx" */),
  "component---src-templates-puzzle-pages-dice-game-test-index-tsx": () => import("./../../../src/templates/puzzlePages/DiceGameTest/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-pages-dice-game-test-index-tsx" */),
  "component---src-templates-puzzle-pages-discrepancies-test-index-tsx": () => import("./../../../src/templates/puzzlePages/DiscrepanciesTest/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-pages-discrepancies-test-index-tsx" */),
  "component---src-templates-puzzle-pages-generic-test-index-tsx": () => import("./../../../src/templates/puzzlePages/GenericTest/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-pages-generic-test-index-tsx" */),
  "component---src-templates-puzzle-pages-hidden-figures-test-index-tsx": () => import("./../../../src/templates/puzzlePages/HiddenFiguresTest/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-pages-hidden-figures-test-index-tsx" */),
  "component---src-templates-puzzle-pages-logic-rule-puzzle-index-tsx": () => import("./../../../src/templates/puzzlePages/LogicRulePuzzle/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-pages-logic-rule-puzzle-index-tsx" */),
  "component---src-templates-puzzle-pages-numbers-test-index-tsx": () => import("./../../../src/templates/puzzlePages/NumbersTest/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-pages-numbers-test-index-tsx" */),
  "component---src-templates-puzzle-pages-picture-puzzle-index-tsx": () => import("./../../../src/templates/puzzlePages/PicturePuzzle/index.tsx" /* webpackChunkName: "component---src-templates-puzzle-pages-picture-puzzle-index-tsx" */),
  "component---src-templates-registration-index-tsx": () => import("./../../../src/templates/registration/index.tsx" /* webpackChunkName: "component---src-templates-registration-index-tsx" */)
}

